import React, { useEffect, useRef, useState } from 'react'
import useServices from '../hooks/useServices'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faTrashAlt, faCopy, faSave, faEdit } from "@fortawesome/free-solid-svg-icons";

const TableCrud = ({columns = [],  tableName}) => {
  const [dataSource, setDataSource] = useState([])
  const {getTableCrud, addTableCrud, delTableCrud} = useServices();
    const formRef = useRef(null)
    
    const handleSubmit = async (e) => {
        e?.preventDefault()
       //console.log(formRef.current)
  
    }
    let columsTypes = {};
    columns.forEach(({dataIndex, inputType})  => columsTypes[dataIndex] = inputType )

    async function handleAdd() {
      let formData = new FormData(formRef.current)
      formData = Object.fromEntries(formData);
      formData = Object.entries(formData).map(([dataIndex, value]) => { if(columsTypes[dataIndex] == "number" ) {value = Number(value)} return [dataIndex, value]  } );
      formData = Object.fromEntries(formData);
      const res = await addTableCrud({tableName, row: formData})
      if(res.error) return;
     if(res.result) {cargaDatos()}
    }
  async function cargaDatos () {
     const res = await getTableCrud(tableName)
     if(res.error) return;
     const data = res?.result.sort((a, b) => b?.created_at || 0 - a?.created_at || 0 )
     setDataSource(res?.result)
  }
  async function handleDelRow (id) { 
   console.log({id})
   const res = await delTableCrud({tableName, findParams: {id}})
   if(res.error) return;
   if(res.result) {cargaDatos()}

  }
  
  useEffect(() => {

    cargaDatos()
 
   }, [])

  
  

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
    
    <table className="table table-sm table-bordered table-hover  texto-small">

    <thead>
      <tr>  {columns.map( col =>  <th key={col.dataIndex} >{col.title}</th> )}  </tr>
   
      <tr>  
    
        {columns.map( col =>  <th key={col.dataIndex} >
          <input  name={col.dataIndex} className="form-control" type={col.inputType || 'text'} />
      </th> 
      
      )} 
            <th>  <button className="btn btn-success btn-sm" onClick={handleAdd} ><b>+</b></button></th>

  
      
       </tr>
     
     </thead>
     <tbody>
        {dataSource.map(row => <tr key={row.id}>
            {columns.map(col => <td>{row?.[col.dataIndex]}</td>)}
            <td>
                  <button
                    onClick={() => {
                      handleDelRow(row.id);
                    }}
                    className="btn btn-danger btn-sm"
                    title="Eliminar registro"
                  >
                    <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                  </button>
                </td>
       
        </tr>)}

     </tbody>
   
     </table>
     </form>
  )
}

export default TableCrud