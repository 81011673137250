import React, { useState, useEffect } from 'react'
import useDocumentTitle from '../hooks/useDocumentTitle';
import FileUploadButton from '../components/FileUploadButton';
import useServices from '../hooks/useServices';
import NominalesControlAUNEAN from '../components/NominalesControlAUNEAN';
import ModalALYCSP from '../components/ModalALYCSP';
const baseURL = process.env.REACT_APP_API_URL;
const ALYC_INTEGRAL = process.env.REACT_APP_ALYC_INTEGRAL === "true";


const ControlNominalesAUNEAN = () => {

    useDocumentTitle('Control Nominales AUNE')
  
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [sumaCOMAFI, setSumaCOMAFI] = useState(true);
    const [alycsP, setAlycsP] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [ultimoContado, setUltimoContado] = useState("");


    const {getNominalesProyectadoAUNE, getUploadedStatusTenValAUNE} = useServices();

    async function reloadUploadedStatus () {
        const res = await getUploadedStatusTenValAUNE();
        if(res.error) return;
        setUploaded(res.uploaded)
    }


    async function cargaDatos () {
        setIsLoading(true)
        const res = await getNominalesProyectadoAUNE(sumaCOMAFI, alycsP);
        setIsLoading(false)
        if(res.error) return;
        const {tablaNominalesProyectados, ultimaOperacionInmediato, ultimaOperacionInmediatoSB} = res;
        setData(tablaNominalesProyectados || []);
        setUltimoContado(ultimaOperacionInmediato);


    }

    useEffect(() => {
    reloadUploadedStatus()
    cargaDatos()
    }, [sumaCOMAFI, alycsP])
    
   function handleSumaComafi () {
    setSumaCOMAFI(prev => !prev)
   } 

   function handleAlycsP () {
    setAlycsP(prev => !prev)
   } 

   console.log({alycsP})


  return (
    <div className="container-fluid">
    <div className="row">
        <div className="col-12">
            <div className="card-header">
                <div className="row">
                    <div className="col-8" style={{display: "flex", justifyContent: "space-between", gap: "6px"}}> 
                        <FileUploadButton endPoint={`${baseURL}upload/uploadTenValAUNE`} fileName={'tenvalores.xlsx'} label={'Cargar archivo de tenencia AUNE T-0:'} callback={cargaDatos} /> 
                        {uploaded ? <span className="float-right input-group-text" >✅</span> : <span className="float-right input-group-text" >❌</span>}
                        
                        <FileUploadButton endPoint={`${baseURL}upload/uploadMovGtiasMAV`} fileName={'movgtiasmav.xls'} label={'Cargar Mov. Gtias MAV'} callback={cargaDatos} /> 
                       {ALYC_INTEGRAL && <ModalALYCSP />}
                     </div>
                     
                     <div className="col">
                     <span className="float-right ml-2 input-group-text form-control-sm"> <b>Últ. Op. CI: {ultimoContado ? ultimoContado : "N/D"}</b></span>
                     { ALYC_INTEGRAL && <>
                     <span className="float-right ml-2 input-group-text form-control-sm">Suma COMAFI <input onChange={handleSumaComafi} checked={sumaCOMAFI} className="ml-1" type="checkbox"  /> </span>
                     <span className="float-right ml-2 input-group-text form-control-sm">Incluye ALYCS P <input onChange={handleAlycsP} checked={alycsP} className="ml-1" type="checkbox"  /> </span>
                     </>
                      }
                     
                     </div>
                 
                </div>
              
         
            </div>
        </div>
    </div>
    <div className="row no-gutters">

               <NominalesControlAUNEAN  data={data} isLoading={isLoading} reloadPanel={cargaDatos}/>
     

    


        </div>

        
        
    </div>
  )
}

export default ControlNominalesAUNEAN