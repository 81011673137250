import React, { useEffect, useState } from "react";
import CardTemplate from "../components/CardTemplate";

//import getPosiOpciones from "../services/getPosiOpciones";
import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";

const PosiOpc = () => {
  useDocumentTitle('Posición Opciones BYMA')
  const [tablaOpc, setTablaOpc] = useState([]);
  const {getPosiOpciones} = useServices();
  const [filtroCuenta, setfiltroCuenta] = useState('')
  const [filtroSerie, setfiltroSerie] = useState('')

  function generaTabla(response) {
    let contenido = [];
    response.pop();

    for (var i in response) {
      if (i > 1) {
        const sdo_tit = response[i][6];
        const sdo_lanz_des = response[i][8];
        const sdo_lanz_cub = response[i][7];

        if (sdo_tit != 0 || sdo_lanz_des != 0 || sdo_lanz_cub != 0) {
          contenido.push({ cuenta: response[i][2], serie: response[i][1], netoc: sdo_tit, netol_des: sdo_lanz_des, netol_cub: sdo_lanz_cub });
        }
      }
    }

    return contenido;
  }

  async function cargaDatos() {
    const datos = await getPosiOpciones();
    if(datos.error) return
    //console.log(generaTabla(datos));
    const tabla = generaTabla(datos);
    setTablaOpc(tabla);
  }

  useEffect(() => {
    cargaDatos();
  }, []);

  return (
    <div className="container-fluid">
    <div className="row">
       <div className="col-sm-0 col-lg-3 col-md-2"></div>
      <div className="col-sm-12 col-lg-6 col-md-8">
        <CardTemplate title={"OPCIONES BYMA"} description={"Posicion por Comitente"}>
        
          <table className="table table-sm table-bordered table-hover">
            <thead className="thead-light">
              <tr>
                <th> <input type="text" value={filtroCuenta} onChange= {(e) => setfiltroCuenta(e.target.value) } placeholder="Cuenta" className="form-control" /> </th>
                <th> <input type="text" value={filtroSerie} onChange= {(e) => setfiltroSerie(e.target.value) } placeholder="Serie" className="form-control" /> </th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
              <th scope="col">Cuenta</th>
                <th scope="col">Serie</th>
                <th scope="col">Neto Comprado</th>
                <th scope="col">Neto Lanzado Descubierto</th>
                <th scope="col">Neto Lanzado Cubierto</th>
              </tr>
            </thead>
            <tbody>
              {tablaOpc.filter(item => item.cuenta.includes(filtroCuenta) && item.serie.includes(filtroSerie)).map((item) => (
                <tr key={item.cuenta + item.serie}>
                  <td>{item.cuenta}</td>
                  <td>{item.serie}</td>
                  <td>{item.netoc}</td>
                  <td>{item.netol_des}</td>
                  <td>{item.netol_cub}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardTemplate>
      </div>
      <div className="col-sm-0 col-lg-6 col-md-2"></div>
    </div>
    </div>
  );
};

export default PosiOpc;
