import React, { useState, useEffect } from "react";
import Paneldheader from "../components/PanelHeader";
import Plazotable from "../components/Plazotable";
import SaldosPanel from "../components/SaldosPanel";
import CaucionTable from "../components/CaucionTable";
import OpcionesTable from "../components/OpcionesTable";
//import getResumenOperdiaContado from "../services/getResumenOperdiaContado";
//import getResumenOperdiaCaucion from "../services/getResumenOperdiaCaucion";
//import getResumenOperdiaOpciones from "../services/getResumenOperdiaOpciones";
//import getSaldosBYMA from "../services/getSaldosBYMA";
import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";
const hasDerivaciones = process.env.REACT_APP_BYMA_DERIVACIONES  === "true";
const hasSENEBI = process.env.REACT_APP_BYMA_SENEBI  === "true";


const Panel = () => {
  useDocumentTitle('Panel de Operaciones BYMA')
  const [infoCI, setInfoCI] = useState([]);
  const [info24hs, setInfo24hs] = useState([]);
  const [info48hs, setInfo48hs] = useState([]);
  const [infoCISB, setInfoCISB] = useState([]);
  const [info24hsSB, setInfo24hsSB] = useState([]);
  const [info48hsSB, setInfo48hsSB] = useState([]);
  const [moneda, setMoneda] = useState("P");
  const [cuentaOper, setCuentaOper] = useState(0);
  const [showCI, setShowCI] = useState(true);
  const [show24hs, setShow24hs] = useState(true);
  
  const [saldosBYMA, setSaldosBYMA] = useState({});
  const [saldosCI, setSaldosCI] = useState({});
  const [saldosCISB, setSaldosCISB] = useState({});
  const [saldosCaucion, setSaldosCaucion] = useState({});
  const [tablaCaucion, setTablaCaucion] = useState({});
  const [tablaOpciones, setTablaOpciones] = useState({});
  const [hayEjercicios, setHayEjercicios] = useState(false);
  const [hayVC, setHayVC] = useState(false)
  const [saldosDerivaciones, setSaldosDerivaciones] = useState(false)
  const [showPanelSENEBI, setShowPanelSENEBI] = useState(false);
  const [isLoading, setIsLoading]  = useState(false);
  const [horaUltimaOperacion, setHoraUltimaOperacion] = useState("");
  const [horaUltimaOperacionCI, setHoraUltimaOperacionCI] = useState("");
  const [origin, setOrigin] = useState("N/D")
  //const [info_procesada, setInfoprocesada] = useState({})

  let info_procesada;

  const {getResumenOperdiaContado, getSaldosBYMA, getResumenOperdiaCaucion, getResumenOperdiaOpciones, getDerivacionesPPTLiqDia, getResumenOperdiaContadoSB} = useServices();

  useEffect(() => {
    initPanel();
  }, []);

  async function carga_oper_gara() {
    let operdia = await getResumenOperdiaContado();
    if(operdia.error) return

    info_procesada = operdia;

    setCuentaOper(info_procesada.cantOper);
    //console.log({ info_procesada });
    setHayEjercicios(info_procesada.hayEjercicios)
    setHayVC(info_procesada.hayVC)
    
    if (info_procesada.tabla != undefined) {
      if (info_procesada.tabla["Inmediato"] != undefined) {
        setInfoCI(info_procesada.tabla["Inmediato"]);
      }
      if (info_procesada.tabla["24hs"] != undefined) {
        setInfo24hs(info_procesada.tabla["24hs"]);
      }
      if (info_procesada.tabla["48hs"] != undefined) {
        setInfo48hs(info_procesada.tabla["48hs"]);
      }
    }
    if (info_procesada.saldos) {
      setSaldosCI(info_procesada.saldos["Inmediato"]);
    }
    if(info_procesada.horaUltimaOperacionCI) {
      setHoraUltimaOperacionCI(info_procesada.horaUltimaOperacionCI)
    }
    if(info_procesada.horaUltimaOperacion) {
      setHoraUltimaOperacion(info_procesada.horaUltimaOperacion)
    }
    if(info_procesada.origen) {
      setOrigin(info_procesada.origen)
    }

    return true;
  }

  async function carga_oper_gara_SB() {
    let operdia = await getResumenOperdiaContadoSB();
    if (operdia.error) return;

    const info_procesada = operdia;

    //setCuentaOper(prev => prev + info_procesada.cantOper);
    //setCuentaOper(info_procesada.cantOper);
    //console.log({ info_procesada });
    
    if(info_procesada.cantOper > 0) { setShowPanelSENEBI(true) }
    
    if (info_procesada.tabla != undefined) {
      if (info_procesada.tabla["Inmediato"] != undefined) {
        setInfoCISB(info_procesada.tabla["Inmediato"]);
      }
      if (info_procesada.tabla["24hs"] != undefined) {
        setInfo24hsSB(info_procesada.tabla["24hs"]);
      }
      if (info_procesada.tabla["48hs"] != undefined) {
        setInfo48hsSB(info_procesada.tabla["48hs"]);
      }
      if (info_procesada.saldosALiq) {
        setSaldosCISB(info_procesada.saldosALiq["Inmediato"]);
      }
    }
    
    return true;
  }


  async function initPanel() {
    setIsLoading(true)
    await carga_oper_gara();
    await cargaResumenCaucGara();
    await cargaResumenOpc();
    await cargaSaldosBYMA();
    if(hasDerivaciones) { await cargaDerivacionesDiaAnt()}
    if(hasSENEBI) { await carga_oper_gara_SB() }
    setIsLoading(false)
  }

  async function reloadContado () {
    setIsLoading(true)
    await carga_oper_gara();
    if(hasSENEBI) { await carga_oper_gara_SB() }
    setIsLoading(false)
  }

  async function reloadPanelSaldos() {
    setIsLoading(true)
    cargaSaldosBYMA();
    await carga_oper_gara();
    await cargaResumenCaucGara();
    if(hasDerivaciones) { await cargaDerivacionesDiaAnt()}
    if(hasSENEBI) { await carga_oper_gara_SB() }
    setIsLoading(false)
  }
  async function cargaDerivacionesDiaAnt () {
    const res = await getDerivacionesPPTLiqDia();
    if(res.error) return;
    setSaldosDerivaciones(res)

  }


  async function cargaResumenCaucGara() {
    const resumenCaucion = await getResumenOperdiaCaucion();
    if(resumenCaucion.error) return
    //console.log({ resumenCaucion });
    if (resumenCaucion.saldos) {
      setSaldosCaucion(resumenCaucion.saldos);
    }
    if (resumenCaucion.tabla) {
      setTablaCaucion(resumenCaucion.tabla);
    }
    return true;
  }

  async function cargaResumenOpc() {
    const resumenOpciones = await getResumenOperdiaOpciones();
    if(resumenOpciones.error) return
    ////console.log(resumenOpciones)

    if (resumenOpciones.tabla) {
      setTablaOpciones(resumenOpciones.tabla);
    }
    return true;
  }

  const showhidePanel = (idpanel) => {
    switch (idpanel) {
      case "CI":
        setShowCI(!showCI);
        break;
      case "24hs":
        setShow24hs(!show24hs);
        break;

      case "SENEBI":
        setShowPanelSENEBI(!showPanelSENEBI);
        break;
    }
  };
  const cargaSaldosBYMA = async () => {
    const saldos = await getSaldosBYMA();
    if(saldos.error) return

    setSaldosBYMA(saldos);
  };

  return (
    <div className="container-fluid">
      <div className="row no-gutters">
        <div className="col"></div>
        <SaldosPanel reloadPanelSaldos={reloadPanelSaldos} saldosCaucion={saldosCaucion} moneda="P" saldosCI={saldosCI} saldosCISB={saldosCISB} saldos={saldosBYMA} saldosDerivaciones={saldosDerivaciones} isLoading={isLoading} />

        <SaldosPanel reloadPanelSaldos={reloadPanelSaldos}  saldosCaucion={saldosCaucion} moneda="D" saldosCI={saldosCI} saldosCISB={saldosCISB} saldos={saldosBYMA} saldosDerivaciones={saldosDerivaciones} isLoading={isLoading} />
        <SaldosPanel reloadPanelSaldos={reloadPanelSaldos}  saldosCaucion={saldosCaucion} moneda="C" saldosCI={saldosCI} saldosCISB={saldosCISB} saldos={saldosBYMA} saldosDerivaciones={saldosDerivaciones} isLoading={isLoading} />
        <div className="col"></div>
      </div>
      <Paneldheader
        showhidePanel={showhidePanel}
        setMoneda={setMoneda}
        moneda={moneda}
        cuentaOper={cuentaOper}
        cargaContado={reloadContado}
        cargaCauciones={cargaResumenCaucGara}
        cargaOpciones={cargaResumenOpc}
        hayEjercicios={hayEjercicios}
        hayVC={hayVC}
        panelShow = {{showCI, show24hs, showPanelSENEBI}}
        horaUltimaOperacion={horaUltimaOperacion}
        horaUltimaOperacionCI={horaUltimaOperacionCI}
        opersOrigin={origin}
      />

      <div className="row no-gutters">
      {showCI && <div className="col-lg col-sm-12 col-md-12">
            <Plazotable moneda={moneda} titulo="CI" datos={infoCI} />
        </div>}
        {show24hs && <div className="col-lg col-sm-12 col-md-12">
         <Plazotable moneda={moneda} titulo="24hs" datos={info24hs} />
        </div>}
      
      </div>

      <div className="row no-gutters">
        <CaucionTable moneda={moneda} datos={tablaCaucion} /> <OpcionesTable datos={tablaOpciones} />
      </div>
      {  (hasSENEBI && showPanelSENEBI) &&    <div className="row no-gutters">
        {showCI && (
          <div className="col-lg col-sm-12 col-md-12">
            <Plazotable moneda={moneda} titulo="CI - SENEBI" datos={infoCISB} />
          </div>
        )}
        {show24hs && (
          <div className="col-lg col-sm-12 col-md-12">
            <Plazotable moneda={moneda} titulo="24hs - SENEBI" datos={info24hsSB} />
          </div>
        )}

      </div>}
    </div>
  );
};

export default Panel;
