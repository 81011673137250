import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faTrashAlt, faCopy, faSave, faEdit } from "@fortawesome/free-solid-svg-icons";
//import {  getUsers } from "../services/listaUsers";
import useServices from "../hooks/useServices";
import { PasswordInput } from "../components/PasswordInput";
const LDAP_AUTH = process.env.REACT_APP_LDAP_AUTH === "true";

const TablaUsers = () => {
  const [datos, setDatos] = useState([]);
  const [userID, setuserID] = useState("");
  const [hasLoginBYMA, sethasLoginBYMA] = useState(false);
  const [userCategory, setuserCategory] = useState("backoffice");
  const [cuentasAsociadas, setcuentasAsociadas] = useState("");
  const [password, setPassword] = useState("");
  const [hasLDAPAuth, setHasLDAPAuth] = useState(false)
  const [userBYMAID, setUserBYMAID] = useState("")
  const { delUser, addUser, getUsers, setUser } = useServices();

  async function cargaDatos() {
    const listaUsers = await getUsers();
    //console.log({ listaUsers });
    setDatos(listaUsers);
  }
  console.log(hasLDAPAuth)
  

  function selectElementContents(el) {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
  }
  async function handleDelUser(id) {
    //console.log(id);
    const proceed = window.confirm(`Esta seguro de borrar el usuario?`);
    if (!proceed) return;
    const respuesta = await delUser(id);

    if (!respuesta.error) {
      setDatos(respuesta);
    }
  }

  async function handleAddUser() {
    let cuentasAsoc = cuentasAsociadas.replace(/ /g, "");
    cuentasAsoc = cuentasAsoc.split(",");
    if (userID == "") return alert("userID no definido");
    if (password == "" && hasLDAPAuth !== 'true') return alert("Password no definido");
    if (userCategory == "trader" && (cuentasAsoc.length == 0 || cuentasAsoc[0] == "")) return alert("Cuentas asociadas no definidas");

    const postData = {
      userID,
      userCategory,
      cuentasAsociadas: cuentasAsoc,
      hasLoginBYMA: userCategory == "trader" ? false : hasLoginBYMA === "true",
      password,
      hasLDAPAuth: hasLDAPAuth === "true",
      userBYMAID
    };
    //console.log(postData)
    const proceed = window.confirm("Estas por agregar un usuario, confirmas?");
    if (!proceed) return;

    const respuesta = await addUser(postData);
    if (respuesta.status == "ERROR") {
      alert(respuesta.message);
      return;
    }

    if (!respuesta.error) {
      claarForm();
      setDatos(respuesta);
    }
  }
  async function handleSetUser(User) {
    User.editable = false;
    //console.log(User)
    const proceed = window.confirm("Esta seguro de sobreescribir los datos?");
    if (!proceed) return;
    User.hasLDAPAuth = typeof User.hasLDAPAuth == 'string' ?  User.hasLDAPAuth === 'true' : User.hasLDAPAuth;
    User.hasLoginBYMA = typeof User.hasLoginBYMA == 'string' ?  User.hasLoginBYMA === 'true' : User.hasLoginBYMA;
    const respuesta = await setUser(User);
    if (!respuesta.error) {
      setDatos(respuesta);
    }
  }
  function setEditable(id) {
    datos.find((item) => item.id == id).editable = true;
    setDatos([...datos]);
  }
  function unSetEditable(id) {
    datos.find((item) => item.id == id).editable = false;
    setDatos([...datos]);
  }

  useEffect(() => {
    cargaDatos();
  }, []);
  function editDato(valor, columna, id) {
    //   console.log(valor, columna, id)
    let newDatos = [...datos];
    if (columna == "cuentasAsociadas") {
      valor = valor.replace(/ /g, "");
      valor = valor.split(",");
    }
    newDatos.find((item) => item.id == id)[columna] = valor;
    setDatos([...newDatos]);
  }

  function claarForm() {
    setuserID("");
    setcuentasAsociadas("");
    setPassword("");
  }

  return (
    <div className="card">
      <div className="card-header ">
        <span className="ml-1">
          <b>Users</b>
        </span>{" "}
        <span className="badge badge-secondary"></span>
        <button
          type="button"
          name=""
          data-toggle="tooltip"
          data-placement="bottom"
          title="Copiar"
          onClick={() => {
            selectElementContents(document.getElementById("tb_comit"));
          }}
          className="btn btn-secondary float-right ml-1"
        >
          <FontAwesomeIcon icon={faCopy} />
        </button>
      </div>

      <div className="card-body">
        <div style={{ maxHeight: "82vh", minHeight: "10vh" }} className="tableFixHead">
          <table id={"tb_comit"} className="table table-sm table-bordered table-hover  ">
            <thead>
              <tr>
                <th scope="col">userID</th>
                <th scope="col">userCategory</th>
                <th scope="col">Login BYMA</th>
                {LDAP_AUTH && <th scope="col">LDAP</th>}
                {LDAP_AUTH && <th scope="col">User BYMA asociado</th>}
                <th scope="col">Cuentas Asociadas</th>
                <th scope="col">Contraseña</th>
              </tr>
              <tr>
                <th>
                  <input value={userID} onChange={(e) => setuserID(e.target.value)} className="form-control" type="text" />
                </th>
                <th>
                  <select onChange={(e) => setuserCategory(e.target.value)} className="form-control" value={userCategory}>
                    {" "}
                    <option value={"admin"}>admin</option> <option value={"trader"}>trader</option>{" "}
                    <option value={"backoffice"}>backoffice</option> 
                    <option value={"api"}>API AnyherePortfolio</option>
                    <option value={"api_mav"}>API MAV Automatico</option>
                    <option value={"api_aune"}>API AUNE</option>
                  </select>
                </th>
                <th>
                  <select
                    onChange={(e) => sethasLoginBYMA(e.target.value)}
                    className="form-control"
                    value={userCategory == "trader" ? false : hasLoginBYMA}
                    disabled={userCategory == "trader" || userCategory == "api"}
                  >
                    {" "}
                    <option value="true">SI</option> <option value="false">NO</option>{" "}
                  </select>
                </th>

                {LDAP_AUTH && (
                  <>
                    <th>
                      <select onChange={(e) => setHasLDAPAuth(e.target.value)} className="form-control" value={hasLDAPAuth}>
                        {" "}
                        <option value="true">SI</option> <option value="false">NO</option>{" "}
                      </select>
                    </th>
                    <th>
                      <input value={userBYMAID} onChange={(e) => setUserBYMAID(e.target.value)} className="form-control" type="text" />
                    </th>
                  </>
                )}

                <th>
                  <input
                    value={cuentasAsociadas}
                    onChange={(e) => setcuentasAsociadas(e.target.value)}
                    placeholder="ej: 2002, 5454, 123456"
                    className="form-control"
                    type="text"
                  />
                </th>
                <th>
                  {/* <input value={password} disabled={hasLDAPAuth === 'true'} onChange={(e) => setPassword(e.target.value)} className="form-control" type="text" /> */}
                  <PasswordInput value={password} disabled={hasLDAPAuth === 'true'} onChange={(e) => setPassword(e.target.value)} containerClassName="input-group" className="form-control" />
                </th>
                <th>
                  <button onClick={() => handleAddUser()} className="btn btn-success btn-sm">
                    <b>+</b>
                  </button>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {datos.map((linea) => (
                <tr key={linea.id}>
                  {!linea.editable && (
                    <>
                      <td> {linea.userID} </td>
                      <td> {linea.userCategory} </td>
                      <td> {linea.hasLoginBYMA ? "SI" : "NO"} </td>
                      {LDAP_AUTH && <>
                        <td> {linea.hasLDAPAuth ? "SI" : "NO"} </td>
                        <td> {linea.userBYMAID} </td>
                       </>}
                      <td> {linea.cuentasAsociadas ? linea.cuentasAsociadas.join(", ") : ""} </td>
                      <td></td>
                    </>
                  )}{" "}
                  {linea.editable && (
                    <>
                      <td>
                        {" "}
                        <input
                          onChange={(e) => editDato(e.target.value, "userID", linea.id)}
                          value={linea.userID}
                          className="form-control"
                          type="text"
                        />{" "}
                      </td>

                      <td>
                        {" "}
                        <select
                          onChange={(e) => editDato(e.target.value, "userCategory", linea.id)}
                          value={linea.userCategory}
                          className="form-control"
                          type="text"
                        >
                          
                          <option value="admin">admin</option>
                          <option value="trader">trader</option>
                          <option value="backoffice">backoffice</option>
                          <option value="api">api</option>
                          <option value="api_mav">api MAV Automatico</option>
                        </select>
                      </td>
                  
                      <td>
                        <select
                          onChange={(e) => editDato(e.target.value, "hasLoginBYMA", linea.id)}
                          value={linea.userCategory == "trader" ? false : linea.hasLoginBYMA}
                          disabled={linea.userCategory == "trader"}
                        >
                          {" "}
                          <option value="true">SI</option>
                          <option value="false">NO</option>{" "}
                        </select>{" "}
                      </td>
                      {LDAP_AUTH && <>
                      <td>
                        <select
                          onChange={(e) => editDato(e.target.value, "hasLDAPAuth", linea.id)}
                          value={linea.hasLDAPAuth}
                      
                        >
                          {" "}
                          <option value="true">SI</option>
                          <option value="false">NO</option>{" "}
                        </select>{" "}
                      </td>
              
                      <td>
                        {" "}
                        <input
                          onChange={(e) => editDato(e.target.value, "userBYMAID", linea.id)}
                          value={linea.userBYMAID}
                          className="form-control"
                          type="text"
                        />
                      </td>
                      </> }
                      <td>
                        <input
                          onChange={(e) => editDato(e.target.value, "cuentasAsociadas", linea.id)}
                          value={linea.cuentasAsociadas}
                          className="form-control"
                          type="text"
                        />
                      </td>
                      <td>
                {/*         <input
                          onChange={(e) => editDato(e.target.value, "newPassword", linea.id)}
                          value={linea?.newPassword}
                          className="form-control"
                          type="text"
                        /> */}
                        <PasswordInput value={linea?.newPassword} onChange={(e) => editDato(e.target.value, "newPassword", linea.id)} containerClassName="input-group" className="form-control" />
                      </td>
                    </>
                  )}
                  <td>
                    <button
                      onClick={() => {
                        handleDelUser(linea.id);
                      }}
                      className="btn btn-danger btn-sm"
                    >
                      <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        setEditable(linea.id);
                      }}
                      className="btn btn-warning btn-sm"
                    >
                      <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                    </button>
                  </td>
                  {linea.editable && (
                    <td>
                      <button
                        onClick={() => {
                          handleSetUser(linea);
                        }}
                        className="btn btn-success btn-sm"
                      >
                        <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>
                      </button>
                      <button
                        onClick={() => {
                          unSetEditable(linea.id);
                        }}
                        className="btn btn-danger btn-sm"
                      >
                        <FontAwesomeIcon icon={faBan}></FontAwesomeIcon>
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TablaUsers;
