import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";


const PosCaucComit = ({ listaFechas = [], tablaPosiciones = [], monedaLabel = "",  fecha = new Date().toLocaleDateString(), trader = false, showLeyenda = false }) => {
  const [filtro, setFiltro] = useState('');
  const [comaValuesEnabled, setComaValuesEnabled] = useState(false);
  console.log({tablaPosiciones, listaFechas})
  
  function handleSetSeparadorDecimales(value) {
    if(value == "coma") {setComaValuesEnabled(true)} 
    if(value == "punto") {setComaValuesEnabled(false)} 
  }
  

  function tabletoClipboard(comaSeparatedValues = false) {
    let tableElem = document.createElement("table");
    let thead = document.createElement("thead");
    const thCuentas = document.createElement("th");
    thCuentas.innerText = "Cuentas";
    thead.append(thCuentas)

    listaFechas.forEach(item => {
      let th = document.createElement("th");
      th.innerText = item.fecha;
      thead.append(th);
    })
    let tbody = document.createElement("tbody");
  
    
   //console.log(tabla)
   tablaPosiciones.filter(item => item[0].includes(filtro)).forEach(row => {
    let tr = document.createElement('tr');
    row.forEach(elem => {
       let td = document.createElement('td');
       td.innerText = elem;
       tr.append(td)
    })
    tbody.append(tr)
   })
   tableElem.append(thead);
   tableElem.append(tbody);

   let table = tableElem.outerHTML;
   if(comaSeparatedValues) {
    table = table.replace(/\./g, "," );
   }
   console.log(table)
   navigator.clipboard.writeText(table)
   .then(() => {
     console.log('Texto copiado al portapapeles')
   })
   .catch(err => {
     console.error('Error al copiar al portapapeles:', err)
     alert("No se pudo copiar")
   })

  }
  
  const formatNumero = (numero) => {
    return numero.toLocaleString(undefined, { maximumFractionDigits: 0 });
  };
  const sumaColumns = (tablaPosiciones) => {
    let linea = [];
    tablaPosiciones.forEach((element) => {
      element.forEach((item, index) => {
        if (index != 0) {
          if (linea[index] == undefined) {
            linea[index] = item;
          } else {
            linea[index] += item;
          }
        }
      });
    });
    return linea;
  };
  const totales = sumaColumns(tablaPosiciones);
  const leyenda = `- negativo: tomé caución, tengo que devolver en esa fecha. Para netear, colocar
- positivo: coloque caución, me van a devolver. Puedo tomar a esa fecha y usar la anterior de garantía`;
  return (
    <div className="card">
    <div className="card-header grey">
      <span >
        <b>{"CAUCION "+monedaLabel}</b> - Posicion por Comitente
      </span>
      {showLeyenda && <span style={{"cursor": "pointer"}} title={leyenda} className="badge badge-secondary ml-2"><FontAwesomeIcon icon={faInfo} /></span>}
      <button type="button" className="btn btn-secondary btn-sm float-right">
        {fecha}
      </button>
      <select title="Separador decimal" value={comaValuesEnabled ? "coma" : "punto"} style={{width: "40px"}} onChange={(e) => {handleSetSeparadorDecimales(e.target.value)} } class="form-control-sm float-right  mr-1 float-right" >
                 <option value="coma">,</option> 
                 <option value="punto">.</option>
                </select>
      <button title="Copiar tabla" className="btn btn-sm btn-success float-right mr-1" onClick={() => tabletoClipboard(comaValuesEnabled)}> <FontAwesomeIcon icon={faCopy} /> </button>

      <input placeholder="Cuenta" value={filtro} onChange={(e) => setFiltro(e.target.value) } className="form-control-sm form-control  float-right mr-1" type="text" style={{"max-width": "70px"}} />
    </div>
    <div className="card-body">
    <div style={{ maxHeight: "60vh", minHeight: "10vh" }} className="tableFixHead table-responsive-md">
        <table className="table table-sm table-bordered table-hover">
          <thead className="thead-light">
            <tr>
              <th>Cuentas</th>
              {listaFechas.map((item) => (
                <th className="text-center" key={item.timest}>{item.fecha} <br/> ({item.dtv})</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tablaPosiciones.filter(itm => itm[0].includes(filtro)).map((item) => (
              <tr key={item[0]}>
                <th scope="col">{item[0]}</th>
                {item.map((itm, index) => index != 0 && <td className="text-right">{formatNumero(itm)}</td>)}
              </tr>
            ))}
            <tr>
              <td className="text-right">{trader ? "Total:"  : "Total ALYC:"}</td>
              {totales.map((item) => (
                <td className="text-right">{formatNumero(item)}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      </div>
    </div>
  );
};

export default PosCaucComit;
